import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

const ImageGallery = ({ images }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-responsive-carousel/lib/styles/carousel.min.css');
    }
  }, []);

  const StyledCarousel = styled(Carousel)`
    .carousel .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .carousel .slide img {
      max-width: 100%;
      max-height: 100%;
    }
  `;

  return (
    <StyledCarousel 
    preventMovementUntilSwipeScrollTolerance={true}
swipeScrollTolerance={50}
    showThumbs={true} 
    showStatus={false}>
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img src={imageUrl} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </StyledCarousel>
  );
};

export default ImageGallery;
